<template>
    <div>
        <div
            v-if="isLoading"
            class="loader state-loader">
            <loader-ui :is-show="true" />
        </div>

        <div v-if="state === 'denied' && cameras.length" class="alert alert-danger">
            <div class="alert-message">
                Доступ к камере закрыт или имеются другие проблемы с камерой
            </div>
        </div>
        <div v-if="!cameras.length" class="alert alert-danger">
            <div class="alert-message">
                Не найдена камера на данном устройстве
            </div>
        </div>

        <div v-if="isLoaded && (isBlob || isFile || isString)" class="row">
            <div class="col-lg-10 offset-lg-1 py-3 text-center">
                <img class="w-100" :src="getUrlResult">
            </div>
        </div>

        <div v-if="isLoaded && isNull && cameras.length" class="row">
            <div class="col-lg-10 offset-lg-1 py-3">
                <video
                    ref="video"
                    autoplay
                    class="video"
                    muted></video>
            </div>
        </div>

        <div class="row">
            <div class="col-12 text-center pt-3">
                <button-ui class="btn-outline-primary" @click="back">
                    Назад
                </button-ui>
                <button-ui
                    v-if="isNull || isString"
                    class="btn-outline-secondary ms-3 position-relative"
                    type="button"
                    @click.stop="fileClick">
                    Прикрепить файл
                </button-ui>
                <button-ui
                    v-if="!isNull && (isString || isFile)"
                    class="btn-outline-secondary ms-3"
                    type="button"
                    @click="reset">
                    Очистить
                </button-ui>
                <button-ui
                    v-if="isNull && isLoaded && cameras.length"
                    class="btn-primary ms-3"
                    type="button"
                    @click="snap">
                    Сфотографировать
                </button-ui>
                <button-ui
                    v-if="!isNull && isBlob && isLoaded && cameras.length"
                    class="btn-outline-secondary ms-3"
                    type="button"
                    @click="reset">
                    Перефотографировать
                </button-ui>
                <button-ui
                    v-if="!isNull"
                    class="btn-primary ms-3"
                    @click="next">
                    Далее
                </button-ui>
            </div>
        </div>

        <input
            ref="file"
            class="upload-files"
            multiple
            type="file"
            @change="selectFile">
    </div>
</template>

<script lang="ts">
import { defineComponent, onMounted, reactive, computed, ref, watch, watchEffect, Ref } from "vue";
import { useDevicesList, useUserMedia } from '@vueuse/core';
import { API_URL } from "@/configs/app";

export default defineComponent({
    name: "ScreenComponent",
    props: {
        src: {
            type: [ Blob, String, File ],
            default: null,
        },
    },
    emits: {
        onNext: null,
        onBack: null,
    },
    setup(props, { emit }) {
        const state = ref('loading');
        const result = ref(props.src || null);
        const camera = ref();
        const video = ref<HTMLVideoElement>();
        const file = ref<HTMLInputElement>();

        const isString = computed(() => typeof result.value === 'string');
        const isNull = computed(() => result.value === null);
        const isBlob = computed(() => result.value instanceof Blob && result.value.constructor.name === 'Blob');
        const isFile = computed(() => result.value instanceof File && result.value.constructor.name === 'File');

        const isLoading = computed(() => state.value === 'loading');
        const isLoaded = computed(() => state.value === 'loaded');
        const isDenied = computed(() => state.value === 'denied');

        const getUrlResult = ref('');

        const { videoInputs: cameras, ensurePermissions } = useDevicesList({
            requestPermissions: true,
            constraints: {
                video: true,
                audio: false,
            },
            onUpdated() {
                if (!cameras.value.find(i => i.deviceId === camera.value))
                    camera.value = cameras.value[0]?.deviceId;
            },
        });

        const { stream, enabled, isSupported } = useUserMedia({
            videoDeviceId: camera.value,
            audioDeviceId: false,
        });

        onMounted(() => {
            ensurePermissions().then((r: any) => {
                state.value = 'loaded';
                enabled.value = true;
            }).catch((r: any) => {
                state.value = 'denied';
            });
        });

        function fileClick() {
            // @ts-ignore
            file.value.click();
        }

        function selectFile(e: any) {
            let file = e.target.files[0];
            if (file) {
                if (/\.(jpe?g|png)$/i.test(file.name)) {
                    result.value = file;
                    setUrlImg(file);
                } else {
                    alert('Неверный формат');
                }
            } else {
                result.value = null;
                getUrlResult.value = '';
            }
        }

        function next() {
            emit('onNext', result.value);
        }

        function back() {
            emit('onBack');
        }

        function setUrlImg(file: any) {
            console.log(file);
            if (isString.value) {
                getUrlResult.value = API_URL + file;
            }
            if (isBlob.value) {
                getUrlResult.value = URL.createObjectURL(file);
            }
            if (isFile.value) {
                let reader = new FileReader();
                reader.onload = (e: any) => {
                    getUrlResult.value = e.target.result;
                };
                reader.readAsDataURL(file);
            }
        }

        function snap() {
            if (video.value) {
                let canvas = document.createElement('canvas') as HTMLCanvasElement;
                let context = canvas.getContext('2d') as CanvasRenderingContext2D;
                let w: any;
                let h: any;
                let ratio: any;

                ratio = video.value.videoWidth / video.value.videoHeight;
                w = video.value.videoWidth - 100;
                // @ts-ignore
                h = parseInt(w / ratio, 10);
                canvas.width = w;
                canvas.height = h;

                context.fillRect(0, 0, w, h);
                context.drawImage(video.value, 0, 0, w, h);

                canvas.toBlob((r: any) => {
                    result.value = r;
                    setUrlImg(r);
                });
            }
        }

        function reset() {
            result.value = null;
            getUrlResult.value = '';
        }

        watchEffect(() => {
            if (video.value) {
                video.value.srcObject = stream.value!;
            }
        });
        // @ts-ignore
        watch(() => result.value, (v) => setUrlImg(v), { immediate: true });
        // @ts-ignore
        watch(() => props.src, (v) => result.value = v, { immediate: true });

        return {
            state,
            file,
            video,
            cameras,
            camera,
            result,
            getUrlResult,
            isString,
            isBlob,
            isFile,
            isNull,
            isLoading,
            isLoaded,
            isDenied,
            fileClick,
            selectFile,
            next,
            back,
            snap,
            reset,
        };
    },
});
</script>

<style scoped lang="scss">
.state-loader {
    height: 50px;
}
.video {
    width: 100%;
}
.upload-files {
    position: absolute;
    top: -10000;
    height: 0;
}
</style>
