<template>
    <modal-ui
        :id="id"
        :title="$t(state.title)">
        <form class="loader" @submit.prevent="formHandler">
            <loader-ui :is-show="state.loader" />
            <div v-if="state.step === 1" class="row">
                <div class="col-12">
                    <div class="alert alert-info">
                        <div class="alert-message">
                            Шаг 1: Заполните все необходимые поля студента
                        </div>
                    </div>
                </div>
                <div v-if="entrances.length !== 1" class="col-12 col-lg-6">
                    <form-group-ui
                        invalid-feedback="Выберите общежитие"
                        label="Общежитие"
                        required>
                        <select-ui
                            :is-invalid="state.step1.visibleErrors && isEntranceIdError"
                            :options="entrances"
                            placeholder="Общежитие"
                            :searchable="true"
                            :values="[ state.step1.entranceId ]"
                            @on-selected="onSelectedEntrance" />
                    </form-group-ui>
                </div>
                <div class="col-12 col-lg-6">
                    <form-group-ui
                        invalid-feedback="Укажите фамилию"
                        label="Фамилия"
                        :required="true">
                        <input-ui
                            v-model="state.step1.lastName"
                            :is-invalid="state.step1.visibleErrors && isLastNameError"
                            placeholder="Фамилия" />
                    </form-group-ui>
                </div>
                <div class="col-12 col-lg-6">
                    <form-group-ui
                        invalid-feedback="Укажите имя"
                        label="Имя"
                        :required="true">
                        <input-ui
                            v-model="state.step1.firstName"
                            :is-invalid="state.step1.visibleErrors && isFirstNameError"
                            placeholder="Имя" />
                    </form-group-ui>
                </div>
                <div class="col-12 col-lg-6">
                    <form-group-ui
                        label="Отчество">
                        <input-ui
                            v-model="state.step1.middleName"
                            placeholder="Отчество" />
                    </form-group-ui>
                </div>
                <div class="col-12 col-lg-6">
                    <form-group-ui
                        :invalid-feedback="isHumanExist ? 'Данный ИИН уже есть в системе' : 'Укажите ИИН'"
                        label="ИИН"
                        :required="true">
                        <input-ui
                            v-model="state.step1.iin"
                            :is-invalid="state.step1.visibleErrors && (isIinError || isHumanExist)"
                            :mask="{ mask: 'Z*', tokens: { 'Z': { pattern: /[0-9]/ }}}"
                            maxlength="12"
                            placeholder="ИИН" />
                    </form-group-ui>
                </div>
                <div class="col-12 col-lg-6">
                    <form-group-ui
                        invalid-feedback="Укажите номер телефона"
                        label="Номер телефона">
                        <input-ui
                            v-model="state.step1.phone"
                            :is-invalid="state.step1.visibleErrors && isPhoneError"
                            placeholder="Номер телефона" />
                    </form-group-ui>
                </div>
                <div class="col-12 col-lg-6">
                    <form-group-ui
                        invalid-feedback="Выберите тип"
                        label="Тип"
                        required>
                        <select-ui
                            :is-invalid="state.step1.visibleErrors && isRoleIdError"
                            :options="humanRoles"
                            placeholder="Тип"
                            :searchable="true"
                            :values="[ state.step1.roleId ]"
                            @on-selected="onSelectedRole" />
                    </form-group-ui>
                </div>
                <div v-if="state.step1.roleId === 1" class="col-12 col-lg-6">
                    <form-group-ui
                        invalid-feedback="Выберите учебное заведение"
                        label="Учебное заведение"
                        required>
                        <select-ui
                            :disabled="state.step1.roleId !== 1"
                            :is-invalid="state.step1.visibleErrors && isInstitutionIdError"
                            :options="institutions"
                            placeholder="Учебное заведение"
                            :searchable="true"
                            :values="[ state.step1.prop1 ]"
                            @on-selected="onSelectedInstitution">
                            <template #option="props">
                                {{ props.option.type === 'college' ? 'Колледж -' : 'Университет -' }} {{ props.option.name }}
                            </template>
                        </select-ui>
                    </form-group-ui>
                </div>
                <div v-if="state.step1.roleId === 1 && typeof getInstitution !== 'undefined' && getInstitution?.type !== 'college'" class="col-12 col-lg-6">
                    <form-group-ui
                        invalid-feedback="Выберите форму обучения"
                        label="Форма обучения"
                        required>
                        <select-ui
                            :disabled="state.step1.roleId !== 1"
                            :is-invalid="state.step1.visibleErrors && isEducationIdError"
                            :options="educations"
                            placeholder="Форма обучения"
                            :searchable="true"
                            :values="[ state.step1.prop2 ]"
                            @on-selected="onSelectedEducation" />
                    </form-group-ui>
                </div>
                <div v-if="state.step1.roleId === 1" class="col-12 col-lg-6">
                    <form-group-ui
                        invalid-feedback="Выберите дату поступления"
                        label="Дата поступления"
                        required>
                        <date-picker
                            timezone="UTC"
                            v-model="state.step1.enteredAt"
                            class="inline-block h-full"
                            :is24hr="true"
                            mode="date">
                            <template #default="{ inputValue, togglePopover }">
                                <div class="flex items-center">
                                    <input
                                        class="form-control"
                                        :class="{'is-invalid': isEnteredAtError && state.step1.visibleErrors, 'bg-white': state.step1.roleId === 1 }"
                                        :disabled="state.step1.roleId !== 1"
                                        placeholder="Дата поступления"
                                        readonly
                                        :timezone="'Asia/Qyzylorda'"
                                        :value="inputValue"
                                        @blur="togglePopover()"
                                        @focus="togglePopover()">
                                    <div class="invalid-feedback">
                                        Укажите дату
                                    </div>
                                </div>
                            </template>
                        </date-picker>
                    </form-group-ui>
                </div>
                <div v-if="state.step1.roleId === 1" class="col-12 col-lg-6">
                    <form-group-ui
                        invalid-feedback="Укажите номер комнаты"
                        label="Номер комнаты">
                        <input-ui
                            v-model="state.step1.roomNumber"
                            :disabled="state.step1.roleId !== 1"
                            :is-invalid="state.step1.visibleErrors && isRoomNumberError"
                            placeholder="Номер комнаты" />
                    </form-group-ui>
                </div>
                <div v-if="state.step1.roleId === 1" class="col-12 col-lg-6">
                    <form-group-ui
                        invalid-feedback="Укажите курс обучения"
                        label="Курс обучения"
                        :required="true">
                        <input-ui
                            v-model="state.step1.courseNumber"
                            :disabled="state.step1.roleId !== 1"
                            :is-invalid="state.step1.visibleErrors && isCourseNumberError"
                            :mask="{ mask: 'Z*', tokens: { 'Z': { pattern: /[0-9]/ }}}"
                            maxlength="1"
                            placeholder="Курс обучения" />
                    </form-group-ui>
                </div>
                <div v-if="state.step1.roleId === 1" class="col-12 col-lg-6">
                    <form-group-ui
                        invalid-feedback="Укажите номер договора"
                        label="Номер договора">
                        <input-ui
                            v-model="state.step1.contractNumber"
                            :disabled="state.step1.roleId !== 1"
                            :is-invalid="state.step1.visibleErrors && isContractNumberError"
                            placeholder="Номер договора" />
                    </form-group-ui>
                </div>
                <div v-if="state.step1.roleId === 1" class="col-12 col-lg-6">
                    <form-group-ui
                        invalid-feedback="Выберите дату заключения договора"
                        label="Дата заключения договора"
                        required>
                        <date-picker
                            timezone="UTC"
                            v-model="state.step1.contractDate"
                            class="inline-block h-full"
                            :is24hr="true"
                            mode="date">
                            <template #default="{ inputValue, togglePopover }">
                                <div class="flex items-center">
                                    <input
                                        class="form-control"
                                        :class="{'is-invalid': isContractDateError && state.step1.visibleErrors, 'bg-white': state.step1.roleId === 1 }"
                                        :disabled="state.step1.roleId !== 1"
                                        placeholder="Дата заключения договора"
                                        readonly
                                        :timezone="'Asia/Qyzylorda'"
                                        :value="inputValue"
                                        @blur="togglePopover()"
                                        @focus="togglePopover()">
                                    <div class="invalid-feedback">
                                        Укажите дату
                                    </div>
                                </div>
                            </template>
                        </date-picker>
                    </form-group-ui>
                </div>
                <div v-if="isCapacityError" class="col-12 mt-2">
                    <div class="alert alert-danger">
                        <div class="alert-message text-center">
                            Превышено число вместимости студентов в общежитие
                        </div>
                    </div>
                </div>
                <div class="col-12 text-center pt-2">
                    <button-ui
                        button-class="btn-primary">
                        {{ $t(state.btn) }}
                    </button-ui>
                </div>
            </div>
            <div v-if="state.step === 2" class="row">
                <div class="col-12">
                    <div class="alert alert-info">
                        <div class="alert-message">
                            Шаг 2: Сфотографируйте лицевую сторону удостоверения или прикрепите файл
                        </div>
                    </div>
                </div>
                <div class="col-12">
                    <screen-component
                        :src="state.step2.firstScreen"
                        @on-back="backStep"
                        @on-next="onFirstScreen" />
                </div>
            </div>
            <div v-if="state.step === 3" class="row">
                <div class="col-12">
                    <div class="alert alert-info">
                        <div class="alert-message">
                            Шаг 3: Сфотографируйте обратную сторону удостоверения или прикрепите файл
                        </div>
                    </div>
                </div>
                <div class="col-12">
                    <screen-component
                        :src="state.step3.secondScreen"
                        @on-back="backStep"
                        @on-next="onSecondScreen" />
                </div>
            </div>
            <div v-if="state.step === 4" class="row">
                <div class="col-12">
                    <div class="alert alert-info">
                        <div class="alert-message">
                            Шаг 4: Сфотографируйте лицевую сторону карточки или прикрепите файл
                        </div>
                    </div>
                </div>
                <div class="col-12">
                    <screen-component
                        :src="state.step4.thirdScreen"
                        @on-back="backStep"
                        @on-next="onThirdScreen" />
                </div>
            </div>
            <div v-if="state.step === 5" class="row">
                <div class="col-12">
                    <div class="alert alert-info">
                        <div class="alert-message">
                            Шаг 5: Загрузите документы в формате JPG, JPEG, PNG или PDF
                        </div>
                    </div>
                </div>
                <div class="col-12 col-lg-6">
                    <div class="mb-3">
                        <label class="form-label w-100">
                            Договор найма жилого помещения
                        </label>
                        <input
                            ref="file1"
                            :class="{ 'is-invalid': state.step5.visibleErrors && isFile1Error }"
                            type="file"
                            @change="selectFile($event, 'file1')">
                        <div class="invalid-feedback">
                            Выберите документ
                        </div>
                    </div>
                </div>
                <div class="col-12 col-lg-6">
                    <div class="mb-3">
                        <label class="form-label w-100">
                            Документ, удостоверяющий личность
                        </label>
                        <input
                            ref="file2"
                            :class="{ 'is-invalid': state.step5.visibleErrors && isFile2Error }"
                            type="file"
                            @change="selectFile($event, 'file2')">
                        <div class="invalid-feedback">
                            Выберите документ
                        </div>
                    </div>
                </div>
                <div class="col-12 col-lg-6">
                    <div class="mb-3">
                        <label class="form-label w-100">
                            Документ, подтверждающий обучение в организации образования
                        </label>
                        <input
                            ref="file3"
                            :class="{ 'is-invalid': state.step5.visibleErrors && isFile3Error }"
                            type="file"
                            @change="selectFile($event, 'file3')">
                        <div class="invalid-feedback">
                            Выберите документ
                        </div>
                    </div>
                </div>
                <div class="col-12 text-center pt-2">
                    <button-ui class="btn-outline-primary" @click="backStep">
                        Назад
                    </button-ui>
                    <button-ui
                        button-class="btn-primary ms-3">
                        {{ $t(state.btn) }}
                    </button-ui>
                </div>
            </div>
            <div v-if="state.step === 6" class="row">
                <div class="col-12">
                    <div class="alert alert-info">
                        <div class="alert-message">
                            Шаг 6: Отсканируйте карточку через считыватель несколько раз, для проверки карточки
                        </div>
                    </div>
                </div>
                <div class="col-12 col-lg-6">
                    <form-group-ui
                        :invalid-feedback="'Коды не совпадают'"
                        label="Первое сканирование">
                        <input-ui
                            v-model="state.step6.code1"
                            :disabled="true"
                            :is-invalid="isCodesError" />
                    </form-group-ui>
                </div>
                <div class="col-12 col-lg-6">
                    <form-group-ui
                        :invalid-feedback="'Коды не сопадают'"
                        label="Второе сканирование">
                        <input-ui
                            v-model="state.step6.code2"
                            :disabled="true"
                            :is-invalid="isCodesError" />
                    </form-group-ui>
                </div>
                <div class="col-12 text-center">
                    <button-ui
                        v-if="state.type === 'add'"
                        class="btn-outline-primary"
                        @click="backStep">
                        Назад
                    </button-ui>
                    <button-ui
                        button-class="btn-primary ms-3"
                        :disabled="isCodesError || !state.step6.code1.length || !state.step6.code2.length">
                        {{ $t(state.btn) }}
                    </button-ui>
                </div>
            </div>
        </form>
    </modal-ui>
</template>

<script lang="ts">
import { defineComponent, onMounted, onUnmounted, reactive, computed, ref, watch, watchEffect, h } from "vue";
import { initModal, isOpen, useModal } from "@/composables/useModal";
import api from "@/api";
import { ICity } from "@/@types/cities";
import { useDevicesList, useUserMedia } from '@vueuse/core';
import CameraComponent from "@/components/CameraComponent.vue";
import ScreenComponent from "@/components/ScreenComponent.vue";
import { socketOn, socketOff } from "@/composables/useSocket";
import jsonToFormData from '@ajoelp/json-to-formdata';
import { DatePicker } from 'v-calendar';
import moment from "moment";

export default defineComponent({
    name: "HumanAEModal",
    props: {
        id: {
            type: String,
            default: '',
        },
    },
    components: {
        ScreenComponent, DatePicker,
    },
    setup(props) {
	    const modal = useModal(props.id);

        const state = reactive({
            title: '',
            btn: '',
            step: 1,
            loader: false,
            type: 'add',
            id: 0,
            step1: {
                entranceId: 0,
                firstName: '',
                lastName: '',
                middleName: '',
                roleId: 1,
                iin: '',
                phone: '',
                prop1: 0,
                prop2: 0,
                enteredAt: null,
                roomNumber: '',
                courseNumber: 0,
                contractNumber: '',
                contractDate: null,
                humanExist: false,
                visibleErrors: false,
            },
            step2: {
                firstScreen: null,
            },
            step3: {
                secondScreen: null,
            },
            step4: {
                thirdScreen: null,
            },
            step5: {
                file1: null,
                file2: null,
                file3: null,
                visibleErrors: false,
            },
            step6: {
                code1: '',
                code2: '',
                lastCode: 0,
            },
        });

        const entrances = ref([]);
        const universities = ref([]);
        const institutions = ref([]);
        const educations = ref([]);
        const humanRoles = ref([]);
        const file1: any = ref(null);
        const file2: any = ref(null);
        const file3: any = ref(null);

        const getInstitution = computed(() => institutions.value.find((i: any) => i.id === state.step1.prop1));

        const isEntranceIdError = computed(() => !state.step1.entranceId);
        const isUniversityIdError = computed(() => state.step1.roleId === 1 && !state.step1.prop1);
        const isInstitutionIdError = computed(() => state.step1.roleId === 1 && !state.step1.prop1);
        const isEducationIdError = computed(() => state.step1.roleId === 1 && !state.step1.prop2);
        const isRoleIdError = computed(() => !state.step1.roleId);
        const isFirstNameError = computed(() => !state.step1.firstName.length);
        const isLastNameError = computed(() => !state.step1.lastName.length);
        const isIinError = computed(() => !state.step1.iin.length);
        const isPhoneError = computed(() => state.step1.phone.length);
        const isEnteredAtError = computed(() => state.step1.roleId === 1 && !state.step1.enteredAt);
        const isFile1Error = computed(() => state.step5.file1 === null);
        const isFile2Error = computed(() => state.step5.file2 === null);
        const isFile3Error = computed(() => state.step5.file3 === null);
        const isRoomNumberError = computed(() => !state.step1.roomNumber.length);
        const isCourseNumberError = computed(() => !state.step1.courseNumber);
        const isContractNumberError = computed(() => !state.step1.contractNumber.length);
        const isContractDateError = computed(() => !state.step1.contractDate);
        const isCodesError = computed(() => state.step6.code1.length && state.step6.code2.length && state.step6.code1 !== state.step6.code2);
        const isHumanExist = computed(() => state.step1.humanExist);

        const isCapacityError = computed(() => {
            if (state.step1.entranceId !== 0) {
                let entrance: any = entrances.value.find((i: any) => i.id === state.step1.entranceId);
                return entrance.capacity <= entrance.humansCount && state.step1.roleId === 1;
            } else {
                return false;
            }
        });

        const errors = computed(() => {
            if (state.step1.roleId === 1) {
                // @ts-ignore
                return isHumanExist.value || isCapacityError.value || isFirstNameError.value || isLastNameError.value || isEntranceIdError.value || isIinError.value || isInstitutionIdError.value || isEnteredAtError.value || isRoomNumberError.value || isCourseNumberError.value || isContractNumberError.value || isContractDateError.value || (getInstitution.value?.type !== 'college' && isEducationIdError.value);
            } else {
                return isFirstNameError.value || isLastNameError.value || isEntranceIdError.value || isIinError.value;
            }
        });

        onMounted((() => {
            // state.title = 'modals.turnstile.titleAdd';
            state.title = 'Добавление студента';
            // state.btn = 'modals.turnstile.btnAdd';
            state.btn = 'Далее';

            api.v1.info.entrances.get().then((r: any) => {
                entrances.value = r.data.data;
                if (r.data.data.length == 1) {
                    state.step1.entranceId = r.data.data[0].id;
                }
            });

            api.v1.info.universities.get().then((r: any) => {
                universities.value = r.data.data;
            });

            api.v1.info.institutions.get().then((r: any) => {
                institutions.value = r.data.data;
            });

            api.v1.info.educations.get().then((r: any) => {
                educations.value = r.data.data;
            });

            api.v1.info.humanRoles.get().then((r: any) => {
                humanRoles.value = r.data.data;
            });

            if (modal.data.hasOwnProperty('human')) {
                if (modal.data.type === 'edit') {
                    state.title = 'Редактирование студента';
                    state.type = 'edit';
                    state.id = modal.data.human.id;
                    state.step1.firstName = modal.data.human.firstName;
                    state.step1.lastName = modal.data.human.lastName;
                    state.step1.middleName = modal.data.human.middleName ? modal.data.human.middleName : '';
                    state.step1.iin = modal.data.human.iin;
                    state.step1.entranceId = modal.data.human.entranceId;
                    state.step1.roleId = modal.data.human.roleId;
                    state.step1.prop1 = modal.data.human.prop1;
                    state.step1.prop2 = modal.data.human.prop2;
                    state.step1.enteredAt = modal.data.human.enteredAt;
                    state.step1.roomNumber = modal.data.human.roomNumber;
                    state.step1.courseNumber = modal.data.human.courseNumber;
                    state.step1.contractNumber = modal.data.human.contractNumber;
                    state.step1.contractDate = modal.data.human.contractDate;
                    state.step1.phone = modal.data.human.phone ? modal.data.human.phone : '';
                    state.step2.firstScreen = modal.data.human.firstScreen;
                    state.step3.secondScreen = modal.data.human.secondScreen;
                    state.step4.thirdScreen = modal.data.human.thirdScreen;
                    state.step5.file1 = modal.data.human.fileOne;
                    state.step5.file2 = modal.data.human.fileTwo;
                    state.step5.file3 = modal.data.human.fileThree;
                }
                if (modal.data.type === 'linkCard') {
                    state.title = 'Перепривязка карты';
                    state.type = 'lostCard';
                    state.id = modal.data.human.id;
                    state.step = 6;
                }
            }

            socketOn('placeCard', (r) => {
                if (isOpen('human_a_e_modal') && state.step === 6) {
                    if (state.step6.lastCode !== 1) {
                        state.step6.code1 = r.code;
                        state.step6.lastCode = 1;
                    } else {
                        state.step6.code2 = r.code;
                        state.step6.lastCode = 2;
                    }
                }
            });
        }));

        onUnmounted(() => {
            // socketOff('placeCard');
        });

        function onSelectedEntrance(entrance: any) {
            state.step1.entranceId = entrance.id;
        }

        function onSelectedUniversity(university: any) {
            state.step1.prop1 = university.id;
        }

        function onSelectedEducation(education: any) {
            state.step1.prop2 = education.id;
        }

        function onSelectedRole(role: any) {
            state.step1.roleId = role.id;
        }

        function onFirstScreen(src: any) {
            state.step2.firstScreen = src;
            state.step = 3;
        }

        function onSecondScreen(src: any) {
            state.step3.secondScreen = src;
            state.step = 4;
        }

        function onSelectedInstitution(institution: any) {
            state.step1.prop1 = institution.id;
        }

        function onThirdScreen(src: any) {
            state.step4.thirdScreen = src;
            state.step = 5;
            // if (state.type === 'add') {
            //     state.step = 5;
            // } else {
            //     api.v1.humans.update(state.id, getBody()).then((r: any) => {
            //         modal.ok({
            //             human: r.data.data.human,
            //         });
            //     }).finally(() => state.loader = false);
            // }
        }

        function selectFile(e: any, type: string) {
            let file = e.target.files[0];
            if (file) {
                if (/\.(jpe?g|png|pdf)$/i.test(file.name)) {
                    // @ts-ignore
                    let file1Name = state.step5.file1?.name;
                    // @ts-ignore
                    let file2Name = state.step5.file2?.name;
                    // @ts-ignore
                    let file3Name = state.step5.file3?.name;

                    if (type === 'file1' && (file2Name === file.name || file3Name === file.name)) {
                        alert('Файл с таким названием уже загружен');
                        file1.value = '';
                        state.step5.file1 = null;
                    } else if (type === 'file2' && (file1Name === file.name || file3Name === file.name)) {
                        alert('Файл с таким названием уже загружен');
                        file2.value = '';
                        state.step5.file2 = null;
                    } else if (type === 'file3' && (file1Name === file.name || file2Name === file.name)) {
                        alert('Файл с таким названием уже загружен');
                        file3.value = '';
                        state.step5.file3 = null;
                    } else if (type === 'file1'){
                        state.step5.file1 = file;
                    } else if (type === 'file2'){
                        state.step5.file2 = file;
                    } else {
                        state.step5.file3 = file;
                    }
                } else {
                    if (type === 'file1'){
                        file1.value = '';
                        state.step5.file1 = null;
                    } else if (type === 'file2'){
                        file2.value = '';
                        state.step5.file2 = null;
                    } else {
                        file3.value = '';
                        state.step5.file3 = null;
                    }
                    alert('Неверный формат');
                }
            } else if (type === 'file1'){
                file1.value = '';
            } else if (type === 'file2'){
                file2.value = '';
            } else {
                file3.value = '';
            }
        }

        function getBody() {
            return jsonToFormData({
                id: state.id,
                firstName: state.step1.firstName,
                lastName: state.step1.lastName,
                middleName: state.step1.middleName,
                iin: state.step1.iin,
                phone: state.step1.phone.replace(/[^0-9]+/g, ''),
                entranceId: state.step1.entranceId,
                roleId: state.step1.roleId,
                prop1: state.step1.prop1,
                prop2: state.step1.prop2,
                roomNumber: state.step1.roomNumber,
                courseNumber: state.step1.courseNumber,
                contractNumber: state.step1.contractNumber,
                // @ts-ignore
                contractDate: state.step1.enteredAt ? moment(state.step1.contractDate).format('YYYY-MM-DD') : null,
                // @ts-ignore
                enteredAt: state.step1.enteredAt ? moment(state.step1.enteredAt).format('YYYY-MM-DD') : null,
                firstScreen: state.step2.firstScreen,
                secondScreen: state.step3.secondScreen,
                thirdScreen: state.step4.thirdScreen,
                file1: state.step5.file1,
                file2: state.step5.file2,
                file3: state.step5.file3,
                rfid: state.step6.code1,
            }, {
                arrayIndexes: true,
                excludeNull: false,
                useDotSeparator: false,
            }, new FormData());
        }

        function formHandler() {
            if (state.step === 1) {
                if (errors.value) {
                    state.step1.visibleErrors = true;
                } else {
                    state.step1.visibleErrors = false;
                    if (state.step1.roleId === 1) {
                        state.step = 2;
                    } else {
                        state.step = 6;
                    }
                }
            } else if (state.step === 5) {
                if (isFile1Error.value || isFile2Error.value || isFile3Error.value) {
                    state.step5.visibleErrors = true;
                } else if (state.type === 'add') {
                    state.step = 6;
                } else {
                    api.v1.humans.update(state.id, getBody()).then((r: any) => {
                        modal.ok({
                            human: r.data.data.human,
                        });
                    }).finally(() => state.loader = false);
                }
            } else if (state.step === 6 && !isCodesError.value) {
                state.loader = true;
                if (state.type === 'add') {
                    api.v1.humans.create(getBody()).then((r: any) => {
                        modal.ok({
                            human: r.data.data.human,
                        });
                    }).finally(() => state.loader = false);
                } else {
                    api.v1.humans.linkCard(state.id, getBody()).then((r: any) => {
                        modal.ok({
                            human: r.data.data.human,
                        });
                    }).finally(() => state.loader = false);
                }
                // createData
            }
            // if (errors.value) {
            //     state.visibleErrors = true;
            // } else {
            //     state.visibleErrors = false;
            //     state.loader = true;
            //     api.v1.turnstiles.create({
            //         name: state.name,
            //         sn: state.sn,
            //         entranceId: state.entranceId,
            //         reverseDirection: state.reverseDirection,
            //     }).then((r: any) => {
            //         modal.ok({
            //             turnstile: r.data.data,
            //         });
            //     }).finally(() => state.loader = false);
            // }
        }

        function backStep() {
            state.step = state.step - 1;
        }

        watch(() => state.step1.iin, (v) => {
            if (v.length) {
                api.v1.humans.checkIin(v).then((r: any) => {
                    state.step1.humanExist = true;
                }).catch((e: any) => {
                    state.step1.humanExist = false;
                });
            }
        });

        return {
            errors,
            state,
            entrances,
            universities,
            educations,
            institutions,
            humanRoles,
            moment,
            getInstitution,
            isEntranceIdError,
            isUniversityIdError,
            isInstitutionIdError,
            isEducationIdError,
            isRoleIdError,
            isFirstNameError,
            isLastNameError,
            isIinError,
            isPhoneError,
            isEnteredAtError,
            isFile1Error,
            isFile2Error,
            isFile3Error,
            isRoomNumberError,
            isCourseNumberError,
            isContractNumberError,
            isContractDateError,
            onSelectedEntrance,
            onSelectedUniversity,
            onSelectedEducation,
            onSelectedInstitution,
            onSelectedRole,
            formHandler,
            onFirstScreen,
            onSecondScreen,
            onThirdScreen,
            backStep,
            selectFile,
            isCodesError,
            file1,
            file2,
            file3,
            isCapacityError,
            isHumanExist,
        };
    },
});
</script>

<style scoped lang="scss">

</style>
