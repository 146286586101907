<template>
    <modal-ui
        :id="id"
        :title="$t(state.title)">
        <form class="loader" @submit.prevent="formHandler">
            <loader-ui :is-show="state.loader" />
            <div class="row">
                <div v-if="entrances.length !== 1" class="col-12 col-lg-6">
                    <form-group-ui
                        invalid-feedback="Выберите общежитие"
                        label="Общежитие"
                        required>
                        <select-ui
                            :is-invalid="state.step1.visibleErrors && isEntranceIdError"
                            :options="entrances"
                            placeholder="Общежитие"
                            :searchable="true"
                            :values="[ state.step1.entranceId ]"
                            @on-selected="onSelectedEntrance" />
                    </form-group-ui>
                </div>
                <div class="col-12 col-lg-6">
                    <form-group-ui
                        invalid-feedback="Укажите фамилию"
                        label="Фамилия"
                        :required="true">
                        <input-ui
                            v-model="state.step1.lastName"
                            :is-invalid="state.step1.visibleErrors && isLastNameError"
                            placeholder="Фамилия" />
                    </form-group-ui>
                </div>
                <div class="col-12 col-lg-6">
                    <form-group-ui
                        invalid-feedback="Укажите имя"
                        label="Имя"
                        :required="true">
                        <input-ui
                            v-model="state.step1.firstName"
                            :is-invalid="state.step1.visibleErrors && isFirstNameError"
                            placeholder="Имя" />
                    </form-group-ui>
                </div>
                <div class="col-12 col-lg-6">
                    <form-group-ui
                        label="Отчество">
                        <input-ui
                            v-model="state.step1.middleName"
                            placeholder="Отчество" />
                    </form-group-ui>
                </div>
                <div class="col-12 col-lg-6">
                    <form-group-ui
                        :invalid-feedback="isHumanExist ? 'Данный ИИН уже есть в системе' : 'Укажите ИИН'"
                        label="ИИН"
                        :required="true">
                        <input-ui
                            v-model="state.step1.iin"
                            :is-invalid="state.step1.visibleErrors && (isIinError || isHumanExist)"
                            :mask="{ mask: 'Z*', tokens: { 'Z': { pattern: /[0-9]/ }}}"
                            maxlength="12"
                            placeholder="ИИН" />
                    </form-group-ui>
                </div>
                <div class="col-12 col-lg-6">
                    <form-group-ui
                        invalid-feedback="Укажите номер телефона"
                        label="Номер телефона">
                        <input-ui
                            v-model="state.step1.phone"
                            :is-invalid="state.step1.visibleErrors && isPhoneError"
                            placeholder="Номер телефона" />
                    </form-group-ui>
                </div>
                <div class="col-12 col-lg-6">
                    <form-group-ui
                        invalid-feedback="Выберите тип"
                        label="Тип"
                        required>
                        <select-ui
                            :is-invalid="state.step1.visibleErrors && isRoleIdError"
                            :options="humanRoles"
                            placeholder="Тип"
                            :searchable="true"
                            :values="[ state.step1.roleId ]"
                            @on-selected="onSelectedRole" />
                    </form-group-ui>
                </div>
                <div v-if="state.step1.roleId === 1" class="col-12 col-lg-6">
                    <form-group-ui
                        invalid-feedback="Выберите учебное заведение"
                        label="Учебное заведение"
                        required>
                        <select-ui
                            :disabled="state.step1.roleId !== 1"
                            :is-invalid="state.step1.visibleErrors && isInstitutionIdError"
                            :options="institutions"
                            placeholder="Учебное заведение"
                            :searchable="true"
                            :values="[ state.step1.prop1 ]"
                            @on-selected="onSelectedInstitution">
                            <template #option="props">
                                {{ props.option.type === 'college' ? 'Колледж -' : 'Университет -' }} {{ props.option.name }}
                            </template>
                        </select-ui>
                    </form-group-ui>
                </div>
                <div v-if="state.step1.roleId === 1 && typeof getInstitution !== 'undefined' && getInstitution?.type !== 'college'" class="col-12 col-lg-6">
                    <form-group-ui
                        invalid-feedback="Выберите форму обучения"
                        label="Форма обучения"
                        required>
                        <select-ui
                            :disabled="state.step1.roleId !== 1"
                            :is-invalid="state.step1.visibleErrors && isEducationIdError"
                            :options="educations"
                            placeholder="Форма обучения"
                            :searchable="true"
                            :values="[ state.step1.prop2 ]"
                            @on-selected="onSelectedEducation" />
                    </form-group-ui>
                </div>
                <div v-if="state.step1.roleId === 1" class="col-12 col-lg-6">
                    <form-group-ui
                        invalid-feedback="Выберите дату поступления"
                        label="Дата поступления"
                        required>
                        <date-picker
                            timezone="UTC"
                            v-model="state.step1.enteredAt"
                            class="inline-block h-full"
                            :is24hr="true"
                            mode="date">
                            <template #default="{ inputValue, togglePopover }">
                                <div class="flex items-center">
                                    <input
                                        class="form-control"
                                        :class="{'is-invalid': isEnteredAtError && state.step1.visibleErrors, 'bg-white': state.step1.roleId === 1 }"
                                        :disabled="state.step1.roleId !== 1"
                                        placeholder="Дата поступления"
                                        readonly
                                        :timezone="'Asia/Qyzylorda'"
                                        :value="inputValue"
                                        @blur="togglePopover()"
                                        @focus="togglePopover()">
                                    <div class="invalid-feedback">
                                        Укажите дату
                                    </div>
                                </div>
                            </template>
                        </date-picker>
                    </form-group-ui>
                </div>
                <div v-if="state.step1.roleId === 1" class="col-12 col-lg-6">
                    <form-group-ui
                        invalid-feedback="Укажите номер комнаты"
                        label="Номер комнаты">
                        <input-ui
                            v-model="state.step1.roomNumber"
                            :disabled="state.step1.roleId !== 1"
                            :is-invalid="state.step1.visibleErrors && isRoomNumberError"
                            placeholder="Номер комнаты" />
                    </form-group-ui>
                </div>
                <div v-if="state.step1.roleId === 1" class="col-12 col-lg-6">
                    <form-group-ui
                        invalid-feedback="Укажите курс обучения"
                        label="Курс обучения"
                        :required="true">
                        <input-ui
                            v-model="state.step1.courseNumber"
                            :disabled="state.step1.roleId !== 1"
                            :is-invalid="state.step1.visibleErrors && isCourseNumberError"
                            :mask="{ mask: 'Z*', tokens: { 'Z': { pattern: /[0-9]/ }}}"
                            maxlength="1"
                            placeholder="Курс обучения" />
                    </form-group-ui>
                </div>
                <div v-if="state.step1.roleId === 1" class="col-12 col-lg-6">
                    <form-group-ui
                        invalid-feedback="Укажите номер договора"
                        label="Номер договора">
                        <input-ui
                            v-model="state.step1.contractNumber"
                            :disabled="state.step1.roleId !== 1"
                            :is-invalid="state.step1.visibleErrors && isContractNumberError"
                            placeholder="Номер договора" />
                    </form-group-ui>
                </div>
                <div v-if="state.step1.roleId === 1" class="col-12 col-lg-6">
                    <form-group-ui
                        invalid-feedback="Выберите дату заключения договора"
                        label="Дата заключения договора"
                        required>
                        <date-picker
                            timezone="UTC"
                            v-model="state.step1.contractDate"
                            class="inline-block h-full"
                            :is24hr="true"
                            mode="date">
                            <template #default="{ inputValue, togglePopover }">
                                <div class="flex items-center">
                                    <input
                                        class="form-control"
                                        :class="{'is-invalid': isContractDateError && state.step1.visibleErrors, 'bg-white': state.step1.roleId === 1 }"
                                        :disabled="state.step1.roleId !== 1"
                                        placeholder="Дата заключения договора"
                                        readonly
                                        :timezone="'Asia/Qyzylorda'"
                                        :value="inputValue"
                                        @blur="togglePopover()"
                                        @focus="togglePopover()">
                                    <div class="invalid-feedback">
                                        Укажите дату
                                    </div>
                                </div>
                            </template>
                        </date-picker>
                    </form-group-ui>
                </div>
                <div class="col-12 text-center pt-2">
                    <button-ui
                        button-class="btn-primary">
                        {{ $t(state.btn) }}
                    </button-ui>
                </div>
            </div>
        </form>
    </modal-ui>
</template>

<script lang="ts">
import { defineComponent, onMounted, onUnmounted, reactive, computed, ref, watch, watchEffect, h } from "vue";
import { initModal, isOpen, useModal } from "@/composables/useModal";
import api from "@/api";
import { socketOn, socketOff } from "@/composables/useSocket";
import jsonToFormData from '@ajoelp/json-to-formdata';
import { DatePicker } from 'v-calendar';
import moment from "moment";

export default defineComponent({
    name: "HumanStepOneEModal",
    props: {
        id: {
            type: String,
            default: '',
        },
    },
    components: {
        DatePicker,
    },
    setup(props) {
	    const modal = useModal(props.id);

        const state = reactive({
            title: '',
            btn: '',
            step: 1,
            loader: false,
            type: 'add',
            id: 0,
            step1: {
                entranceId: 0,
                firstName: '',
                lastName: '',
                middleName: '',
                roleId: 1,
                iin: '',
                phone: '',
                prop1: 0,
                prop2: 0,
                enteredAt: null,
                roomNumber: '',
                courseNumber: 0,
                contractNumber: '',
                contractDate: null,
                humanExist: false,
                visibleErrors: false,
            },
        });

        const entrances = ref([]);
        const universities = ref([]);
        const institutions = ref([]);
        const educations = ref([]);
        const humanRoles = ref([]);

        const getInstitution = computed(() => institutions.value.find((i: any) => i.id === state.step1.prop1));

        const isEntranceIdError = computed(() => !state.step1.entranceId);
        const isUniversityIdError = computed(() => state.step1.roleId === 1 && !state.step1.prop1);
        const isInstitutionIdError = computed(() => state.step1.roleId === 1 && !state.step1.prop1);
        const isEducationIdError = computed(() => state.step1.roleId === 1 && !state.step1.prop2);
        const isRoleIdError = computed(() => !state.step1.roleId);
        const isFirstNameError = computed(() => !state.step1.firstName.length);
        const isLastNameError = computed(() => !state.step1.lastName.length);
        const isIinError = computed(() => !state.step1.iin.length);
        const isPhoneError = computed(() => state.step1.phone.length);
        const isEnteredAtError = computed(() => state.step1.roleId === 1 && !state.step1.enteredAt);
        const isRoomNumberError = computed(() => !state.step1.roomNumber);
        const isCourseNumberError = computed(() => !state.step1.courseNumber);
        const isContractNumberError = computed(() => !state.step1.contractNumber);
        const isContractDateError = computed(() => !state.step1.contractDate);
        const isHumanExist = computed(() => state.step1.humanExist);

        const errors = computed(() => {
            if (state.step1.roleId === 1) {
                // @ts-ignore
                return isHumanExist.value || isFirstNameError.value || isLastNameError.value || isEntranceIdError.value || isIinError.value || isInstitutionIdError.value || isEnteredAtError.value || isRoomNumberError.value || isCourseNumberError.value || isContractNumberError.value || isContractDateError.value || (getInstitution.value?.type !== 'college' && isEducationIdError.value);
            } else {
                return isFirstNameError.value || isLastNameError.value || isEntranceIdError.value || isIinError.value;
            }
        });

        onMounted((() => {
            // state.title = 'modals.turnstile.titleAdd';
            state.title = 'Добавление студента';
            // state.btn = 'modals.turnstile.btnAdd';
            state.btn = 'Сохранить';

            api.v1.info.entrances.get().then((r: any) => {
                entrances.value = r.data.data;
                if (r.data.data.length == 1) {
                    state.step1.entranceId = r.data.data[0].id;
                }
            });

            api.v1.info.universities.get().then((r: any) => {
                universities.value = r.data.data;
            });

            api.v1.info.institutions.get().then((r: any) => {
                institutions.value = r.data.data;
            });

            api.v1.info.educations.get().then((r: any) => {
                educations.value = r.data.data;
            });

            api.v1.info.humanRoles.get().then((r: any) => {
                humanRoles.value = r.data.data;
            });

            if (modal.data.hasOwnProperty('human')) {
                if (modal.data.type === 'edit') {
                    state.title = 'Редактирование общих данных';
                    state.type = 'edit';
                    state.id = modal.data.human.id;
                    state.step1.firstName = modal.data.human.firstName;
                    state.step1.lastName = modal.data.human.lastName;
                    state.step1.middleName = modal.data.human.middleName ? modal.data.human.middleName : '';
                    state.step1.iin = modal.data.human.iin;
                    state.step1.entranceId = modal.data.human.entranceId;
                    state.step1.roleId = modal.data.human.roleId;
                    state.step1.prop1 = modal.data.human.prop1;
                    state.step1.prop2 = modal.data.human.prop2;
                    state.step1.enteredAt = modal.data.human.enteredAt;
                    state.step1.roomNumber = modal.data.human.roomNumber;
                    state.step1.courseNumber = modal.data.human.courseNumber;
                    state.step1.contractNumber = modal.data.human.contractNumber;
                    state.step1.contractDate = modal.data.human.contractDate;
                    state.step1.phone = modal.data.human.phone ? modal.data.human.phone : '';
                }
            }
        }));

        onUnmounted(() => {
            // socketOff('placeCard');
        });

        function onSelectedEntrance(entrance: any) {
            state.step1.entranceId = entrance.id;
        }

        function onSelectedUniversity(university: any) {
            state.step1.prop1 = university.id;
        }

        function onSelectedEducation(education: any) {
            state.step1.prop2 = education.id;
        }

        function onSelectedRole(role: any) {
            state.step1.roleId = role.id;
        }

        function onSelectedInstitution(institution: any) {
            state.step1.prop1 = institution.id;
        }

        function formHandler() {
            if (errors.value) {
                state.step1.visibleErrors = true;
            } else {
                state.step1.visibleErrors = false;
                api.v1.humans.updateStepOne(state.id, {
                    firstName: state.step1.firstName,
                    lastName: state.step1.lastName,
                    middleName: state.step1.middleName,
                    iin: state.step1.iin,
                    phone: state.step1.phone.replace(/[^0-9]+/g, ''),
                    entranceId: state.step1.entranceId,
                    roleId: state.step1.roleId,
                    prop1: state.step1.prop1,
                    prop2: state.step1.prop2,
                    roomNumber: state.step1.roomNumber,
                    courseNumber: state.step1.courseNumber,
                    contractNumber: state.step1.contractNumber,
                    // @ts-ignore
                    contractDate: state.step1.enteredAt ? moment(state.step1.contractDate).format('YYYY-MM-DD') : null,
                    // @ts-ignore
                    enteredAt: state.step1.enteredAt ? moment(state.step1.enteredAt).format('YYYY-MM-DD') : null,
                }).then((r: any) => {
                    modal.ok({
                        human: r.data.data.human,
                    });
                }).finally(() => state.loader = false);
            }
        }

        watch(() => state.step1.iin, (v) => {
            if (v.length) {
                api.v1.humans.checkIin(v, state.id).then((r: any) => {
                    state.step1.humanExist = true;
                }).catch((e: any) => {
                    state.step1.humanExist = false;
                });
            }
        });

        return {
            errors,
            state,
            entrances,
            universities,
            educations,
            institutions,
            humanRoles,
            moment,
            getInstitution,
            isEntranceIdError,
            isUniversityIdError,
            isInstitutionIdError,
            isEducationIdError,
            isRoleIdError,
            isFirstNameError,
            isLastNameError,
            isIinError,
            isPhoneError,
            isEnteredAtError,
            isRoomNumberError,
            isCourseNumberError,
            isContractNumberError,
            isContractDateError,
            onSelectedEntrance,
            onSelectedUniversity,
            onSelectedEducation,
            onSelectedInstitution,
            onSelectedRole,
            formHandler,
            isHumanExist,
        };
    },
});
</script>

<style scoped lang="scss">

</style>
